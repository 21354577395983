
    .hero-section {
        display: block;
        background-image: url('../assets/Hero-Wave.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 110em;
        padding-bottom: 90em;
    }
    
    .hero-outer-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero-inner-container {
        grid-column: 1 / 3;
        grid-row: 1;
    
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.1fr;
        grid-template-rows: 1fr 1fr 1.5fr 1fr 1fr 1fr;
        height: 90vh;
    
        max-width: 120rem;
        width: 100%;
    
        margin: 1% 12.5% 0rem 12.5%;
    }
    
    .hero-image {
        grid-column: 3 / 6;
        grid-row: 1 / 5;
        padding-top: 0rem;
        background-image: url('../assets/Hero-Image.svg');
        background-repeat: no-repeat;
        background-size: 90%;
        background-position-x: right;
        background-position-y: center;
    }
    
    .hero-title {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        font-size: 4.6rem;
        color: #353535;
        padding: 1rem 0rem;
    }
    
    .hero-subtitle {
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        font-size: 4rem;
        color: #656565;
        margin-top: 1rem;
        padding-bottom: 8rem;
    }
    
    .hero-btn-signup {
        grid-column: 1 / 3;
        grid-row: 4 / 5;
    }
    
    .hero-btn-discover {
        grid-column: 2 / 5;
        grid-row: 5 / 6;
        text-align: center;
    }
    

@media screen and (max-width: 2560px) {

.hero-section {
    display: block;
    background-image: url('../assets/Hero-Wave.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 110em;
    padding-bottom: 90em;
}

.hero-outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-inner-container {
    grid-column: 1 / 3;
    grid-row: 1;

    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.1fr;
    grid-template-rows: 1fr 1fr 1.5fr 1fr 1fr 1fr;
    height: 90vh;

    max-width: 120rem;
    width: 100%;

    margin: 1% 12.5% 0rem 12.5%;
}

.hero-image {
    grid-column: 3 / 6;
    grid-row: 1 / 5;
    padding-top: 0rem;
    background-image: url('../assets/Hero-Image.svg');
    background-repeat: no-repeat;
    background-size: 90%;
    background-position-x: right;
    background-position-y: center;
}

.hero-title {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    font-size: 4.6rem;
    color: #353535;
    padding: 1rem 0rem;
}

.hero-subtitle {
    grid-column: 1 / 3;
    grid-row: 2 / 4;
    font-size: 3rem;
    color: #656565;
    margin-top: 1rem;
    padding-bottom: 8rem;
}

.hero-btn-signup {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    margin-top: 150px;
}

.hero-btn-discover {
    grid-column: 2 / 5;
    grid-row: 5 / 6;
    text-align: center;
}

}

@media screen and (max-width: 960px) {

    .hero-section {
        background-position-y: 110em;
    }
    
    .hero-image {
        background-size: 100%;
    }
    
    .hero-title {
        font-size: 4rem;
    }
    
    .hero-subtitle {
        font-size: 2rem;
        padding-bottom: 6rem;
    }

}



@media screen and (max-width: 680px) {

    .hero-section {
        background-image: url('../assets/Hero-Wave-Mobile.svg');
        background-position-y: 130em;
        margin-top: 2rem;
    }
    
    .hero-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0em 10% 0em 10%;
    }

    .hero-image {
        display: block;
        margin: 0rem auto;
        padding-top: 50%;
        width: 100%;
        background-image: url('../assets/Hero-Image.svg');
        background-repeat: no-repeat;
        background-size: 80%;
        background-position: center;
    }
    
    .hero-title {
        display: block;
        text-align: center;
        margin: 0rem auto;
        width: 100%;
        font-size: 4rem;
        color: #353535;
        padding: 0em;
    }
    
    .hero-subtitle {
        display: block;
        text-align: center;
        margin: 2rem auto;
        width: 100%;
        font-size: 2rem;
        color: #656565;
        padding-bottom: 1rem;
    }
    
    .hero-btn-signup {
        margin: 2rem auto;
    }
    
    .hero-btn-discover {
        margin: 1rem auto;
    }

}



@media screen and (max-width: 500px) {

    .hero-section {
        background-image: url('../assets/Hero-Wave-Mobile.svg');
        background-position-y: 120em;
        margin-top: 4rem;
    }
    
    .hero-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0em 5% 0em 5%;
    }

    .hero-image {
        padding-top: 55%;
        width: 100%;
    }

    .hero-subtitle {
        font-size: 2rem;
        padding-bottom: 1rem;
    }
    
    .hero-btn-signup {
        margin: 1rem auto 0rem auto;
    }

    .hero-btn-discover {
        margin: 2rem auto;
    }
    
}



@media screen and (max-width: 400px) {

    .hero-section {
        background-image: url('../assets/Hero-Wave-Mobile.svg');
        background-position-y: 130em;
        margin-top: 1rem;
    }
    
    .hero-inner-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0em 5% 0em 5%;
    }

    .hero-image {
        padding-top: 55%;
        width: 100%;
    }

    .hero-subtitle {
        font-size: 1.8rem;
        padding-bottom: 0rem;
    }
    
    .hero-btn-signup {
        margin: 1rem auto 1rem auto;
    }

    .hero-btn-discover {
        margin: 1rem auto 1rem auto;
        margin-top: -60px;
    }
    
}

@media screen and (max-width: 350px) {

    .hero-section {
        background-position-y: 100em;
        margin-top: 0rem;
    }

    .hero-image {
        padding-top: 55%;
        width: 100%;
    }
    
}





@media screen 
    and (max-width: 960px)
    and (orientation: landscape) {

    .hero-section {
        background-position-y: 90em;
    }

    .hero-inner-container {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 3fr 1fr 1fr 1fr;
        height: 90vh;
    
        margin: 3% 10% 0rem 10%;
    }
    
    .hero-image {
        background-size: 100%;
    }
    
    .hero-title {
        font-size: 4rem;
    }
    
    .hero-subtitle {
        font-size: 2rem;
        padding-bottom: 6rem;
    }

    .hero-btn-signup {
        margin: 0rem 0rem 0rem 0rem;
    }
    
    .hero-btn-discover {
        margin: 0rem 0rem 0rem 0rem;
        padding: 0rem 0rem 0rem 0rem;
    }

}



@media screen 
    and (max-width: 680px)
    and (orientation: landscape) {

        .hero-section {
            background-position-y: 110em;
            margin-top: 0rem;
        }

        .hero-inner-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 0rem 10% 0rem 10%;
        }

        .hero-image {
            padding-top: 50%;
            width: 80%;
        }

        .hero-subtitle {
            font-size: 2rem;
            padding-bottom: 0rem;
        }
        
        .hero-btn-signup {
            margin: 1rem auto 0rem auto;
        }
    
        .hero-btn-discover {
            margin: 1rem auto 2rem auto;
        }

}
