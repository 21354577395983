
.contact {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact--flex-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    max-width: 150rem;

    margin: 8rem 20% 8rem 20%;

    /* border: 0.2rem solid var(--primary); */
}

.contact--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.contact--subtitle {
    font-size: 2.4rem;
    color: #656565;
    margin-top: 1rem;
    text-align: center;
}

.contact--form {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 4fr 1fr 1fr;

    width: 80%;

    grid-column-gap: 2.5%;

    margin: 4rem 0rem 8rem 0rem;
}

.contact--form--first-name {
    grid-column: 1;
    grid-row: 1;
    width: 100%;

    font-size: 2.4rem;
}

.contact--form--last-name {
    grid-column: 2;
    grid-row: 1;
    width: 100%;

    font-size: 2.4rem;
}

.contact--form--email {
    grid-column: 1 / 3;
    grid-row: 2;
    width: 100%;

    font-size: 2.4rem;
}

.contact--form--subject {
    grid-column: 1 / 3;
    grid-row: 3;
    width: 100%;

    font-size: 2.4rem;
}

.contact--form--message {
    grid-column: 1 / 3;
    grid-row: 4;
    width: 100%;

    font-size: 2.4rem;
}

.contact--form--input {
    width: 100%;
    font-size: 1.8rem;
    color: #353535;

    height: 4rem;

    text-indent: 1rem;

    border: none;
    border-radius: 0.25rem;
    outline: none !important;
    border: 0.15rem solid var(--primary);
}

.contact--form--input:focus {
    outline: none !important;
    border: 0.25rem solid var(--primary);
}

.contact--form--textarea {
    width: 100%;
    font-size: 1.8rem;
    color: #353535;
    resize: vertical;
    min-height: 16rem;
    max-height: 32rem;
    height: 16rem;

    padding: 0rem 1rem;

    border: none;
    border-radius: 0.25rem;
    outline: none !important;
    border: 0.15rem solid var(--primary);
}

.contact--form--textarea:focus {
    outline: none !important;
    border: 0.25rem solid var(--primary);
}

::placeholder {
    color: var(--primary);
}

.contact--form--feedback {
    grid-column: 1 / 3;
    grid-row: 5;
    width: 100%;

    font-size: 2rem;
    color: red;
    transition: all 1s ease-in;
}

.contact--form--feedback-none {
    grid-column: 1 / 3;
    grid-row: 5;
    width: 100%;

    display: none;
}

.contact--form--submit {
    grid-column: 1 / 3;
    grid-row: 6;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 3840px) {

    .contact--flex-container { 
        margin: 8rem 10% 0rem 10%;
    }

    .contact--title {
        font-weight: normal;
        font-size: 4.5rem;
        color: #353535;
        text-align: center;
    }
    
    .contact--subtitle {
        font-size: 3rem;
        color: #656565;
        margin-top: 1rem;
        text-align: center;
    }
    


    .contact--form--input {
        width: 100%;
        font-size: 3rem;
        color: #353535;
    
        height: 4rem;
    
        text-indent: 1rem;
    
        border: none;
        border-radius: 0.25rem;
        outline: none !important;
        border: 0.15rem solid var(--primary);
    }

    .contact--form--textarea {
        width: 100%;
        font-size: 3rem;
        color: #353535;
        resize: vertical;
        min-height: 16rem;
        max-height: 32rem;
        height: 16rem;
    
        padding: 0rem 1rem;
    
        border: none;
        border-radius: 0.25rem;
        outline: none !important;
        border: 0.15rem solid var(--primary);
    }
}


@media screen and (max-width: 2560px) {

    .contact--flex-container { 
        margin: 8rem 10% 0rem 10%;
    }

    .contact--title {
        font-weight: normal;
        font-size: 4.5rem;
        color: #353535;
        text-align: center;
    }
    
    .contact--subtitle {
        font-size: 3rem;
        color: #656565;
        margin-top: 1rem;
        text-align: center;
    }
    


    .contact--form--input {
        width: 100%;
        font-size: 3rem;
        color: #353535;
    
        height: 4rem;
    
        text-indent: 1rem;
    
        border: none;
        border-radius: 0.25rem;
        outline: none !important;
        border: 0.15rem solid var(--primary);
    }

    .contact--form--textarea {
        width: 100%;
        font-size: 3rem;
        color: #353535;
        resize: vertical;
        min-height: 16rem;
        max-height: 32rem;
        height: 16rem;
    
        padding: 0rem 1rem;
    
        border: none;
        border-radius: 0.25rem;
        outline: none !important;
        border: 0.15rem solid var(--primary);
    }
}


@media screen and (max-width: 960px) {

    .contact--flex-container { 
        margin: 8rem 10% 0rem 10%;
    }

}


@media screen and (max-width: 820px) {

    .contact--form {
        width: 100%;
    }

}


@media screen and (max-width: 680px) {

    .contact--flex-container { 
        margin: 4rem 5% 0rem 5%;
    }

    .contact--title {
        font-size: 3rem;
    }
    
    .contact--subtitle {
        font-size: 2rem;
        margin-top: 1rem;
    }

}
