.games--background-v2 {
    margin-top: 10rem;
    background-color: #ffffff;
}

.games--game {
    margin-top: -2rem;
    width: 100%;
    display: flex;
}

.games--game--card {
    width: 35%;
}

.games--game--content-container {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
}

.games--game--title {
    font-weight:bold;
    font-size: 2.8rem;
    color: #353535;
    text-align: center;
}

.games--subtitle {
    font-size: 1.8rem;
}

.games--game--video--container {
    width: 70%;
    margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
}

.games--game--video--responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-left: auto;
    margin-right: auto;
    width:100%;
}

.games--game--video--iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.games--game--description {
    align-self: flex-start;
    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0rem;
}

.software--paragraph {
    font-size: 1.8rem;
    margin-top: 3rem;
}

@media screen and (max-width: 3840px) {
    .software--paragraph {
        font-size: 2.8rem;
        color: #353535;
        text-align: center;
    
        margin-top: 4rem;
    }

    .games--subtitle {
        font-size: 3rem;
    }

    .games--game--title {
        font-weight:bold;
        font-size: 2.8rem;
        margin-top: 12px;
        color: #353535;
        text-align: center;
    }    

    .games--game--description {
        align-self: flex-start;
        font-weight: normal;
        font-size: 2.5rem;
        color: #353535;
        text-align: center;
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 0rem;
    }



}


@media screen and (max-width: 2560px) {
    .software--paragraph {
        font-size: 2.8rem;
        color: #353535;
        text-align: center;
    
        margin-top: 4rem;
    }

    .games--subtitle {
        font-size: 3rem;
    }

    .games--game--title {
        font-weight:bold;
        font-size: 2.8rem;
        margin-top: 12px;
        color: #353535;
        text-align: center;
    }    

    .games--game--description {
        align-self: flex-start;
        font-weight: normal;
        font-size: 2.5rem;
        color: #353535;
        text-align: center;
        margin-left: 5rem;
        margin-right: 5rem;
        margin-top: 0rem;
    }



}

@media screen and (max-width: 960px) {
    .games--background-v2 {
        margin-top: 10rem;
        background-color: #ffffff;
    }
    
    .games--game {
        margin-top: -2rem;
        width: 100%;
        display: inherit;
    }
    
    .games--game--card {
        width: 100%;
        align-self: center;
    }
    
    .games--game--content-container {
        width: 90%;
        margin-top: auto;
        margin-bottom: auto;
    }
    
    .games--game--title {
        font-weight:bold;
        font-size: 2.8rem;
        color: #353535;
        text-align: center;
    }

    .games--subtitle {
        font-size: 1.8rem;
    }
    
    .games--game--video--container {
        width: 90%;
        margin-top: 4rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 4rem;
    }

    .games--game--video--responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.games--game--video--iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.games--game--description {
    align-self: flex-start;
    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0rem;
}

.software--paragraph {
    font-size: 1.6rem;
    margin-top: 3rem;
}


}