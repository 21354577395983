/* Footer */
.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background: var(--primary);
    color: #ffffff;
    width: 100%;
    margin-top: 0rem;
}

.footer--container {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto;

    max-width: 150rem;

    margin: 0rem 15% 0rem 15%;
}

/* UbiTheraPlay */
.footer--ubitheraplay {
    grid-column: 1 / 3;
    grid-row: 1 / 2;

    margin-top: 6rem;
}

.footer--ubitheraplay--link {
    font-weight: normal;
    font-size: 3.6rem;
    color: #ffffff;
}

/* Nav Links */
.footer--nav-links {
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    margin-top: 1rem;
}

.footer--nav-links--ul {
    list-style: none;
}

.footer--nav-links--link {
    font-weight: normal;
    font-size: 1.8rem;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

/* UbiTech */
.footer--ubitech {
    grid-column: 4 / 6;
    grid-row: 1 / 4;

    margin-top: 4rem;
    padding-top: 0rem;
}

.footer--ubitech-logo {
    display: block;
    margin-left: auto;
    width: 80%;
}

/* Address */
.footer--ubitech-address {
    grid-column: 3 / 4;
    grid-row: 2 / 3;

    margin: 1rem 0rem 0rem 0rem;
}

.footer--ubitech-address--p {
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    color: #ffffff;
}

/* Social Links */
.footer--social {
    grid-column: 1 / 6;
    grid-row: 3 / 4;

    margin-top: 4rem;
}

.footer--social--list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    list-style-type: none;
}

.footer--social--link {
    margin: 0rem 3rem 0rem 3rem;
}

.footer--social--link--a {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 3.6rem;
    color: #ffffff;

    text-align: center;

    width: 4rem;
    height: 4rem;
    line-height: 3.5rem;
    
    border-radius: 0.4rem;
    box-shadow: 0em 0rem 0.2rem 0.2rem rgba(255, 255, 255, 0.6);

    margin-bottom: 2rem;
    
}

/* Copyright */
.footer--copyright {
    grid-column: 1 / 6;
    grid-row: 4 / 5;

    text-align: center;
    font-weight: normal;
    font-size: 1.8rem;

    margin: 3rem 0rem;
}

@media screen and (max-width: 2560px) {

    .footer--container {
        grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
        margin: 0rem 10% 0rem 10%;
    }

    .footer--ubitech-logo {
        margin-top: 2rem;
        width: 110%;
    }

    .footer--ubitech-address {
        margin: 1rem 1rem 0rem 0rem;
    }

    .footer--ubitech-address--p {
        font-style: normal;
        font-weight: normal;
        font-size: 2.5rem;
        color: #ffffff;
    }

    .footer--nav-links--link {
        font-weight: normal;
        font-size: 2.5rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer--copyright {
        grid-column: 1 / 6;
        grid-row: 4 / 5;
    
        text-align: center;
        font-weight: normal;
        font-size: 2.5rem;
    
        margin: 3rem 0rem;
    }
}



@media screen and (max-width: 960px) {

    .footer--container {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto;

        margin: 0rem 5% 0rem 5%;
    }

    /* UbiTheraPlay */
    .footer--ubitheraplay {
        grid-column: 1;
        grid-row: 1;

        margin-top: 6rem;
    }

    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 3.6rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* Nav Links */
    .footer--nav-links {
        grid-column: 1;
        grid-row: 2;

        margin-top: 2rem;
    }

    .footer--nav-links--ul {
        list-style: none;
    }

    .footer--nav-links--link {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* UbiTech */
    .footer--ubitech {
        grid-column: 2;
        grid-row: 1;

        margin-top: 1rem;
        padding-top: 0rem;
    }

    .footer--ubitech-logo {
        display: block;
        margin-left: none;
        width: 60%;
    }

    .footer--ubitech-address {
        grid-column: 2;
        grid-row: 2;

        margin: 2rem 0rem 0rem 0rem;
    }

    .footer--ubitech-address--p {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
    }

    /* Social Links */
    .footer--social {
        grid-column: 1 / 3;

        margin-top: 3rem;
    }

    .footer--social--link {
        margin: 0rem 1rem 0rem 1rem;
    }

    /* Copyright */
    .footer--copyright {
        grid-column: 1 / 3;
        grid-row: 4 / 5;

        text-align: center;
        font-weight: normal;
        font-size: 1.8rem;
    }

}



@media screen and (max-width: 820px) {

    /* Social Links */
    .footer--social--link {
        margin: 0rem 2rem 0rem 2rem;
    }

    .footer--ubitech-logo {
        margin-top: 2rem;
        width: 100%;
    }

    .footer--container {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto;

        margin: 0rem 5% 0rem 5%;
    }

    /* UbiTheraPlay */
    .footer--ubitheraplay {
        grid-column: 1;
        grid-row: 1;

        margin-top: 6rem;
    }

    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 3.6rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* Nav Links */
    .footer--nav-links {
        grid-column: 1;
        grid-row: 2;

        margin-top: 2rem;
    }

    .footer--nav-links--ul {
        list-style: none;
    }

    .footer--nav-links--link {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* UbiTech */
    .footer--ubitech {
        grid-column: 2;
        grid-row: 1;

        margin-top: 1rem;
        padding-top: 0rem;
    }

    .footer--ubitech-logo {
        display: block;
        margin-left: none;
        width: 60%;
    }

    .footer--ubitech-address {
        grid-column: 2;
        grid-row: 2;

        margin: 2rem 0rem 0rem 0rem;
    }

    .footer--ubitech-address--p {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
    }

    /* Social Links */
    .footer--social {
        grid-column: 1 / 3;

        margin-top: 3rem;
    }

    .footer--social--link {
        margin: 0rem 1rem 0rem 1rem;
    }

    /* Copyright */
    .footer--copyright {
        grid-column: 1 / 3;
        grid-row: 4 / 5;

        text-align: center;
        font-weight: normal;
        font-size: 1.8rem;
    }


    
}



@media screen and (max-width: 680px) {
        
    .footer--container {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto;

        margin: 0rem 5% 0rem 5%;
    }

    /* UbiTheraPlay */
    .footer--ubitheraplay {
        grid-column: 1;
        grid-row: 1;

        margin-top: 6rem;
    }

    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 3.6rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* Nav Links */
    .footer--nav-links {
        grid-column: 1;
        grid-row: 2;

        margin-top: 2rem;
    }

    .footer--nav-links--ul {
        list-style: none;
    }

    .footer--nav-links--link {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    /* UbiTech */
    .footer--ubitech {
        grid-column: 2;
        grid-row: 1;

        margin-top: 1rem;
        padding-top: 0rem;
    }

    .footer--ubitech-logo {
        display: block;
        margin-left: none;
        width: 60%;
    }

    .footer--ubitech-address {
        grid-column: 2;
        grid-row: 2;

        margin: 2rem 0rem 0rem 0rem;
    }

    .footer--ubitech-address--p {
        font-weight: normal;
        font-size: 1.8rem;
        color: #ffffff;
    }

    /* Social Links */
    .footer--social {
        grid-column: 1 / 3;

        margin-top: 3rem;
    }

    .footer--social--link {
        margin: 0rem 1rem 0rem 1rem;
    }

    /* Copyright */
    .footer--copyright {
        grid-column: 1 / 3;
        grid-row: 4 / 5;

        text-align: center;
        font-weight: normal;
        font-size: 1.8rem;
    }

}



@media screen and (max-width: 500px) {

    /* Footer */   
    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 2.5rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer--nav-links--link {
        font-size: 1.6rem;
    }

    .footer--ubitech-logo {
        width: 40%;
        margin-top: 3.5rem;
    }

    .footer--ubitech-address--p {
        font-size: 1.6rem;
    }

    /* Social Links */
    .footer--social {
        margin-top: 2rem;
    }

    .footer--social--link {
        margin: 0rem 1rem 0rem 1rem;
    }

    .footer--social--link--a {
        font-size: 3rem;

        width: 3.5rem;
        height: 3.5rem;
        line-height: 3.25rem;

        margin-bottom: 1rem;
    }

    /* Copyright */
    .footer--copyright {
        font-size: 1.6rem;
    }

}



@media screen and (max-width: 400px) {

    .footer--ubitheraplay--link {
        font-weight: normal;
        font-size: 2.4rem;
        color: #ffffff;
        cursor: pointer;
        text-decoration: none;
    }

    .footer--nav-links--link {
        font-size: 1.4rem;
    }

    .footer--ubitech-logo {
        width: 40%;
        margin-top: 3.5rem;
    }

    .footer--ubitech-address--p {
        font-size: 1.4rem;
    }

    /* Social Links */
    .footer--social--link {
        margin: 0rem 1rem 0rem 1rem;
    }

    .footer--social--link--a {
        font-size: 3rem;
    }

    /* Copyright */
    .footer--copyright {
        font-size: 1.4rem;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 340px) {

    .footer--nav-links--link {
        font-size: 1.3rem;
    }

    .footer--ubitech-address--p {
        font-size: 1.3rem;
    }

    /* Social Links */
    .footer--social--link {
        margin: 0rem 0.5rem 0rem 0.5rem;
    }

    /* Copyright */
    .footer--copyright {
        font-size: 1.3rem;
        margin-top: 1rem;
    }

}