.news--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 150rem;
    margin: 8rem 15% 16rem 15%;
}

.news--container--article {
    margin-top: 4rem;
}

.news--heading {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.news--title {
    font-weight: normal;
    font-size: 4.5rem;
    color: #353535;
    text-align: center;
}

.news--subtitle {
    font-size: 3.5rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.news--card-section {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    margin-top: 6rem;
}

.news--card-section--hidden {
    display: none;
}

.news--article-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    max-width: 150rem;
    margin: 6rem 15% 4rem 15%;

    border: 0.2rem solid var(--primary);
    border-radius: 0.5rem;
}

.news--article-title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
    margin: 4rem 1rem 2rem 1rem;
}

.news--article-paragraph {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;
    width: 60%;
    margin: 6rem 2rem 4rem 2rem;
}

.news--article-button {
    margin: 2rem 1rem 8rem 1rem;
}


@media screen and (max-width: 3840px) {

    .news--container {
        margin: 2rem 2.5% 4rem 2.5%;
    }

    .news--article-title {
        font-size: 3rem;
        margin: 4rem 1rem 2rem 1rem;
    }

    .news--article-paragraph {
        font-size: 2.5rem;
        width: 100%;
        padding: 0rem 2rem 0rem 2rem;
    }

    .news--article-button {
        margin: 1rem 1rem 6rem 1rem;
    }

}


@media screen and (max-width: 2560px) {

    .news--container {
        margin: 2rem 2.5% 4rem 2.5%;
    }

    .news--article-title {
        font-size: 3rem;
        margin: 4rem 1rem 2rem 1rem;
    }

    .news--article-paragraph {
        font-size: 2.5rem;
        width: 100%;
        padding: 0rem 2rem 0rem 2rem;
    }

    .news--article-button {
        margin: 1rem 1rem 6rem 1rem;
    }

}



@media screen and (max-width: 1800px) {

    .news--article-paragraph {
        width: 80%;
    }

}



@media screen and (max-width: 1200px) {

    .news--article-paragraph {
        width: 90%;
    }

}



@media screen and (max-width: 960px) {

    .news--container {
        margin: 8rem 10% 8rem 10%;
    }

    .news--container--article {
        margin-top: 0rem;
    }

    .news--article-paragraph {
        width: 100%;
        padding: 0rem 4rem 0rem 4rem;
    }

}



@media screen and (max-width: 680px) {

    .news--container {
        margin: 4rem 5% 8rem 5%;
    }

    .news--title {
        font-size: 3rem;
    }

    .news--subtitle {
        font-size: 2rem;
    }

}



@media screen and (max-width: 500px) {

    .news--container {
        margin: 2rem 2.5% 4rem 2.5%;
    }

    .news--article-title {
        font-size: 3rem;
        margin: 4rem 1rem 2rem 1rem;
    }

    .news--article-paragraph {
        font-size: 1.6rem;
        width: 100%;
        padding: 0rem 2rem 0rem 2rem;
    }

    .news--article-button {
        margin: 1rem 1rem 6rem 1rem;
    }

}



@media screen and (max-width: 300px) {

    .news--container {
        margin: 0rem 2.5% 4rem 2.5%;
    }

    .news--article-title {
        font-size: 3rem;
        margin: 4rem 1rem 2rem 1rem;
    }

    .news--article-paragraph {
        font-size: 1.6rem;
        width: 100%;
        padding: 0rem 2rem 0rem 2rem;
    }

    .news--article-button {
        margin: 1rem 1rem 6rem 1rem;
    }

}
