.navbar {
    background: #ffffff;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-top: 0rem;
    padding: 8rem 0rem 6rem 0rem;

    position: sticky;
    top: 0;
    z-index: 999;

    transition: 0.6s;

    /* border: 0.2rem solid var(--primary); */
}

.navbar-shadow {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);

    padding: 3rem 0rem 3rem 0rem;
    transition: 0.2s;
}

.navbar-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 8rem;
    max-width: 100%;
}

.navbar-logo-link {
    display: flex;
    align-items: center;
    justify-self: start;
    margin-left: 3rem;
    cursor: pointer;
    text-decoration: none;
}

.navbar-logo-image {
    padding-top: 22%;
    width: 30rem;
    background-image: url('../assets/newlogo.png');
    background-repeat: no-repeat;
    background-size: 75%;
    background-position-x: left;
    background-position-y: center;
}

.navbar-logo-text {
    color: #353535;
    font-size: 4rem;
}

.navbar-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 1rem;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
    font-size: 2.4rem;
}

.navbar-item {
    height: 6rem;
}

.navbar-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
}

.fa-bars.navbar-bars-icon {
    color: #353535;
}

.navbar-menu-icon {
    display: none;
}

/* Navbar Social Links */
.navbar--social {
    position: absolute;
    top: 12rem;
    right: 2rem;
    width: auto;
    height: auto;

    margin-top: 4rem;
}

.navbar--social--list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    list-style-type: none;
}

.navbar--social--link {
    margin: 0rem 0rem 0rem 0rem;
}

.navbar--social--link--a {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    color: var(--primary);

    text-align: center;

    width: 4rem;
    height: 4rem;
    line-height: 3.5rem;
    
    border-radius: 0.4rem;
    box-shadow: 0em 0rem 0.1rem 0.15rem var(--primary);

    margin-bottom: 2rem;
}

@media screen and (max-width: 1090px) {

    .navbar {
        position: relative;
    }

    .navbar-shadow {
        box-shadow: none;
    }

    .navbar-menu {
        display: flex;
        flex-direction: column;

        justify-self: start;
        justify-content: flex-start;

        width: 100%;
        height: 90vh;
        position: absolute;
        top: 9rem;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

        padding-top: 6rem;
    }

    .navbar-menu.active {
        background: #ffffff;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .navbar-item {
        height: 6rem;
    }

    .navbar-links {
        display: block;
        text-align: center;
        margin: 0rem auto;
        width: 40%;
        padding-top: 0rem;
        transition: all 0.3s ease-out;
    }

    .navbar-logo-link {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .disabled {
        pointer-events: none;
      }

    .navbar-menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-150%, 20%);
        font-size: 4rem;
        cursor: pointer;
    }

    .fa-times.navbar-times-icon {
        color: #353535;
        font-size: 2.8rem;
    }

    /* Navbar Social Links */
    .navbar--social {
        right: 2.5rem;
    }

    .navbar--social--link--a {
        font-size: 3.6rem;

        width: 3.5rem;
        height: 3.5rem;
        line-height: 3.25rem;
    }

}



@media screen and (max-width: 680px) {

    .navbar-logo-link {
        transform: translate(15%, 75%);
    }

    .navbar-logo-image {
        width: 12rem;
        padding-top: 30%;
    }
    
    .navbar-logo-text {
        font-size: 2.5rem;
    }
    

    /* Navbar Social Links */
    .navbar--social {
        display: none;
    }

}



@media screen and (max-width: 400px) {

    .navbar-links {
        display: block;
        text-align: center;
        margin: 2rem auto;
        width: 40%;
        padding-top: 1.4rem;
        transition: all 0.3s ease-out;
    }

    .navbar-menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-130%, 45%);
        font-size: 3.2rem;
        cursor: pointer;
    }

    .fa-times.navbar-times-icon {
        color: #353535;
        font-size: 2.2rem;
    }

    .navbar-logo-image {
        width: 11rem;
        padding-top: 35%;
    }

}




@media screen 
    and (max-width: 960px)
    and (max-height: 500px)
    and (orientation: landscape) {

    .navbar-item {
        height: 3rem;
    }

    .navbar-links {
        display: block;
        text-align: center;
        margin: 2rem auto;
        width: 40%;
        padding-top: 0rem;
        transition: all 0.3s ease-out;
    }

}
