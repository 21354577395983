
/* Software Section */
.software {
    display: flex;
    justify-content: center;
    align-items: center;
}

.software--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    max-width: 150rem;

    margin: 8rem 15% 0rem 15%;
}

.software--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
}

.software--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;

    margin-top: 1rem;
}

.software--paragraph {
    font-size: 1.8rem;
    color: #353535;
    text-align: center;

    margin-top: 4rem;
}


/* Games Section */
.games {
    display: block;
    background-image: url('../assets/Landing-Games-Background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: top;
    padding-bottom: 20rem;
}

.games--outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.games--container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 150rem;
    margin: 0rem 15% 0rem 15%;
}

.games--title {
    font-weight: normal;
    font-size: 3.6rem;
    color: #353535;
    text-align: center;
    margin-top: 10rem;
}

.games--subtitle {
    font-size: 2.4rem;
    color: #656565;
    text-align: center;
    margin-top: 1rem;
}

.games--background {
    margin-top: 12rem;
    background-color: #ffffff;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto auto;
    width: 100%;
}

@media screen and (max-width: 3840px) {

    .software--title {
        font-weight: normal;
        font-size: 4.5rem;
        color: #353535;
        text-align: center;
    }

    .software--subtitle {
        font-size: 2.8rem;
        color: #656565;
        text-align: center;
    
        margin-top: 1rem;
    }

    .games--title {
        font-weight: normal;
        font-size: 4.5rem;
        color: #353535;
        text-align: center;
        margin-top: 10rem;
    }
    

}


@media screen and (max-width: 2560px) {

    .software--title {
        font-weight: normal;
        font-size: 4.5rem;
        color: #353535;
        text-align: center;
    }

    .software--subtitle {
        font-size: 2.8rem;
        color: #656565;
        text-align: center;
    
        margin-top: 1rem;
    }

    .games--title {
        font-weight: normal;
        font-size: 4.5rem;
        color: #353535;
        text-align: center;
        margin-top: 10rem;
    }
    

}


@media screen and (max-width: 960px) {

    
    /* Software Section */
    .software--container {
        margin: 8rem 10% 0rem 10%;
    }

    .software--title {
        font-size: 3rem;
    }

    .software--subtitle {
        font-size: 2rem;
        margin-top: 1rem;
    }

    .software--paragraph {
        font-size: 1.6rem;
        margin-top: 3rem;
    }


    /* Games Section */
    .games--title {
        font-size: 3rem;
        margin-top: 8rem;
    }
    
    .games--subtitle {
        font-size: 1.8rem;
    }

}


@media screen and (max-width: 820px) {

    .games {
        background-image: url('../assets/Landing-Games-Background-Tablet.svg');
    }

    .games--container {
        margin: 0rem 10% 0rem 10%;
    }

}


@media screen and (max-width: 680px) {

    /* Software Section */
    .software--container {
        margin: 4rem 10% 0rem 10%;
    }

    /* Games Section */
    .games {
        background-image: url('../assets/Landing-Games-Background-Mobile.svg');
    }

    .games--container {
        margin: 0rem 6% 0rem 6%;
    }

    .games--title {
        margin-top: 8rem;
    }

    .games--background {
        margin-top: 6rem;
    }

}
