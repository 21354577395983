.news-page {
    margin: 0rem 1rem 6rem 1rem;
}

.news-card--background {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 2.5fr 0.25fr auto 0.25fr;

    width: 30.2rem;
    
    padding: 0rem;
    background-color: #ffffff;
    border: 0.1rem solid #dadada;
    border-radius: 0.5rem;
    box-shadow: 0.2rem 0.2rem 1rem #999999;
    transition: all 0.2s ease-in;
    cursor: pointer;
}

.news-card--background:hover {
    transform: translateY(-0.2%);
    box-shadow: 0.4rem 0.4rem 1.6rem #797979;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    cursor: default;
}

.news-card--image {
    grid-column: 1;
    grid-row: 1 / 2;

    width: 30rem;
    height: 22.5rem;
    background-image: url('../assets/Logo.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.news-card--title {
    grid-column: 1;
    grid-row: 2 / 3;

    font-weight: normal;
    font-size: 2.4rem;
    color: #353535;
    text-align: flex-start;
    padding: 0rem 2rem;

    margin-top: 1rem;
    
}

.news-card--content {
    grid-column: 1;
    grid-row: 3 / 4;

    align-self: flex-start;

    font-weight: normal;
    font-size: 1.8rem;
    color: #353535;
    text-align: flex-start;
    padding: 0rem 2rem;

    

    margin-top: 1rem;
}

.news-card--button {
    grid-column: 1;
    grid-row: 4 / 5;
    margin: 2rem 0rem;
}

.news-card--button-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


@media screen and (max-width: 2560px) {
    .news-card--background {
        display: grid;
        justify-content: center;
        align-content: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1.5fr 0.25fr auto 0.25fr;
    
        width: 22.2rem;
    
        padding: 0rem;
        padding-top: 0rem;
        background-color: #ffffff;
        border: 0.1rem solid #dadada;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 1rem #999999;
        transition: all 0.2s ease-in;
        cursor: pointer;
    }
    
    .news-card--background:hover {
        transform: translateY(-0.2%);
        box-shadow: 0.4rem 0.4rem 1.6rem #797979;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
    }
    
    .news-card--image {
        grid-column: 1;
        grid-row: 1 / 2;
    
        width: 22rem;
        height: 16.5rem;
        background-image: url('../assets/Logo.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-x: center;
        background-position-y: top;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    
    .news-card--title {
        grid-column: 1;
        grid-row: 2 / 3;
    
        font-weight: normal;
        font-size: 2.7rem;
        color: #353535;
        text-align: center;
        padding: 0rem 2rem;
    
        margin-top: 1rem;
        
    }
    
    .news-card--content {
        grid-column: 1;
        grid-row: 3 / 4;
    
        align-self: center;
    
        font-weight: normal;
        font-size: 2.1rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        
    
        margin-top: 1rem;
    }
    
    .news-card--button {
        grid-column: 1;
        grid-row: 4 / 5;
        margin: 1rem 0rem;
    }
    
    .news-card--button-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}



@media screen and (max-width: 1200px) {
    .news-card--background {
        display: grid;
        justify-content: center;
        align-content: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1.5fr 0.25fr auto 0.25fr;
    
        width: 22.2rem;
    
        padding: 0rem;
        background-color: #ffffff;
        border: 0.1rem solid #dadada;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 1rem #999999;
        transition: all 0.2s ease-in;
        cursor: pointer;
    }
    
    .news-card--background:hover {
        transform: translateY(-0.2%);
        box-shadow: 0.4rem 0.4rem 1.6rem #797979;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
    }
    
    .news-card--image {
        grid-column: 1;
        grid-row: 1 / 2;
    
        width: 22rem;
        height: 16.5rem;
        background-image: url('../assets/Logo.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-x: center;
        background-position-y: top;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    
    .news-card--title {
        grid-column: 1;
        grid-row: 2 / 3;
    
        font-weight: normal;
        font-size: 2.2rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        margin-top: 1rem;
        
    }
    
    .news-card--content {
        grid-column: 1;
        grid-row: 3 / 4;
    
        align-self: flex-start;
    
        font-weight: normal;
        font-size: 1.6rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        
    
        margin-top: 1rem;
    }
    
    .news-card--button {
        grid-column: 1;
        grid-row: 4 / 5;
        margin: 1rem 0rem;
    }
    
    .news-card--button-center {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}



@media screen and (max-width: 820px) {
    .landing-page {
        margin-bottom: 4rem;
    }

    .news-card--background {
        display: grid;
        justify-content: center;
        align-content: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: 2.5fr 0.25fr auto 0.25fr;
    
        width: 30.2rem;
    
        padding: 0rem;
        background-color: #ffffff;
        border: 0.1rem solid #dadada;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 1rem #999999;
        transition: all 0.2s ease-in;
        cursor: pointer;
    }
    
    .news-card--background:hover {
        transform: translateY(-0.2%);
        box-shadow: 0.4rem 0.4rem 1.6rem #797979;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
    }
    
    .news-card--image {
        grid-column: 1;
        grid-row: 1 / 2;
    
        width: 30rem;
        height: 22.5rem;
        background-image: url('../assets/Logo.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-x: center;
        background-position-y: top;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    
    .news-card--title {
        grid-column: 1;
        grid-row: 2 / 3;
    
        font-weight: normal;
        font-size: 2.4rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        margin-top: 1rem;
        
    }
    
    .news-card--content {
        grid-column: 1;
        grid-row: 3 / 4;
    
        align-self: flex-start;
    
        font-weight: normal;
        font-size: 1.8rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        
    
        margin-top: 1rem;
    }
    
    .news-card--button {
        grid-column: 1;
        grid-row: 4 / 5;
        margin: 2rem 0rem;
    }
    
    .news-card--button-center {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }    
}



@media screen and (max-width: 500px) {
    .news-card--background {
        display: grid;
        justify-content: center;
        align-content: center;
        align-items: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1.5fr 0.25fr auto 0.25fr;
    
        width: 22.2rem;
    
        padding: 0rem;
        background-color: #ffffff;
        border: 0.1rem solid #dadada;
        border-radius: 0.5rem;
        box-shadow: 0.2rem 0.2rem 1rem #999999;
        transition: all 0.2s ease-in;
        cursor: pointer;
    }
    
    .news-card--background:hover {
        transform: translateY(-0.2%);
        box-shadow: 0.4rem 0.4rem 1.6rem #797979;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
    }
    
    .news-card--image {
        grid-column: 1;
        grid-row: 1 / 2;
    
        width: 22rem;
        height: 16.5rem;
        background-image: url('../assets/Logo.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position-x: center;
        background-position-y: top;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    
    .news-card--title {
        grid-column: 1;
        grid-row: 2 / 3;
    
        font-weight: normal;
        font-size: 2.2rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        margin-top: 1rem;
        
    }
    
    .news-card--content {
        grid-column: 1;
        grid-row: 3 / 4;
    
        align-self: flex-start;
    
        font-weight: normal;
        font-size: 1.6rem;
        color: #353535;
        text-align: flex-start;
        padding: 0rem 2rem;
    
        
    
        margin-top: 1rem;
    }
    
    .news-card--button {
        grid-column: 1;
        grid-row: 4 / 5;
        margin: 1rem 0rem;
    }
    
    .news-card--button-center {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}
